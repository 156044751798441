import ky from 'ky';
import { or } from 'ramda';

type RequestType = 'TIPS' | 'API';

export const request = (service: RequestType) => {
  const prefixUrlMap = {
    // API: window.REACT_APP_ADMIN_API_URL,
    // TIPS: window.REACT_APP_BASE_TIPS_URL,localhost:3000
    // API: 'https://api.chainik.fun',
    // TIPS: 'https://tips.chainik.fun',
    // API: 'https://api.chainik.pro',
    // TIPS: 'https://tips.chainik.pro',
    API: or(window.API_SERVICE_PROXY_URL, 'https://api.chainik.pro'),
    TIPS: or(window.TIPS_SERVICE_PROXY_URL, 'https://tips.chainik.pro'),
  };
  // console.log('prefixUrlMap', prefixUrlMap[service], prefixUrlMap, service);
  return ky.create({
    credentials: 'include',
    prefixUrl: prefixUrlMap[service],
    throwHttpErrors: false,
    timeout: 30000,
  });
};
