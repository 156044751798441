import {
  Button,
  Flex,
  LoadingOverlay,
  Menu,
  Paper,
  Stack,
  Text,
  Title,
  TextInput,
  Select,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { OrgEntityType, StatusValueType } from 'entities/entities-types';
import { $$getManyOrg } from 'entities/org';
import {
  $getManyUsers,
  changeStatusActivateUserFx,
  changeStatusBlockUserFx,
  changeStatusSuspendUserFx,
  getManyUsersFx,
} from 'entities/users';
import { groupBy } from 'ramda';
import { useEffect, useState, useMemo } from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { orgStatusColor, orgStatusTranslate } from 'shared/lib';

export const Users = () => {
  const navigate = useNavigate();

  const handleButtonClick = (orgId: number) =>
    navigate(`/profile-org/${orgId}`);

  const { 0: users, 1: isLoading } = useUnit([
    $getManyUsers,
    getManyUsersFx.pending,
  ]);

  // Состояние для поискового запроса и порядка сортировки
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState<string>('desc'); // Сортировка по убыванию по умолчанию

  useEffect(() => {
    getManyUsersFx({ statuses: [] });
  }, []);

  const mapStatusUser = {
    blocked: {
      textRu: 'Заблокировать',
      textEn: 'blocked',
      onclick: changeStatusBlockUserFx,
    },
    active: {
      textRu: 'Разблокировать',
      textEn: 'active',
      onclick: changeStatusActivateUserFx,
    },
    suspended: {
      textRu: 'Заморозить',
      textEn: 'suspended',
      onclick: changeStatusSuspendUserFx,
    },
  };
  const statusesUser: ('active' | 'suspended' | 'blocked')[] = [
    'blocked',
    'active',
    'suspended',
  ];

  // Использование useMemo для мемоизации отфильтрованных пользователей
  const filteredUsers = useMemo(() => {
    return users.filter((user) =>
      `${user.lastname} ${user.firstname}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
  }, [users, searchQuery]);

  // Использование useMemo для мемоизации отсортированных пользователей
  const sortedUsers = useMemo(() => {
    return filteredUsers.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();

      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB; // В зависимости от порядка сортировки
    });
  }, [filteredUsers, sortOrder]);

  return (
    <Stack>
      <Stack mt={60}>
        <LoadingOverlay h='100vh' visible={isLoading} />
        <Title mb={60} ta='center' order={3}>
          Все юзеры
        </Title>

        {/* Компонент для поиска */}
        <TextInput
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          placeholder='Поиск по имени'
          mb={20}
        />

        {/* Выбор порядка сортировки */}
        <Select
          value={sortOrder}
          onChange={(value: string | null) => setSortOrder(value ?? 'desc')}
          data={[
            { label: 'По дате (по убыванию)', value: 'desc' },
            { label: 'По дате (по возрастанию)', value: 'asc' },
          ]}
          mb={20}
          placeholder='Выберите порядок сортировки'
        />

        <Stack align='center'>
          <Stack maw={500} align='center'>
            {sortedUsers.map((user) => (
              <Paper w='100%' key={user.userId} radius={10} shadow='xs' p='md'>
                <Flex align='center' justify='space-between'>
                  <Stack gap={5}>
                    <Text fw='bold' fz={18}>
                      {user.lastname} {user.firstname}
                    </Text>
                    <Flex gap={6}>
                      <Text c='gray'>Статус: {user.status};</Text>
                      <Text c='gray'>ID: {user.userId}</Text>
                      <Text c='gray'>
                        Дата создания:{' '}
                        {new Date(user.createdAt).toLocaleDateString()}
                      </Text>
                    </Flex>
                  </Stack>
                  <Menu>
                    <Menu.Target>
                      <Button w={155}>Изменить статус</Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      {statusesUser.map(
                        (item) =>
                          item !== mapStatusUser[user.status].textEn && (
                            <Menu.Item
                              onClick={() =>
                                mapStatusUser[item].onclick({
                                  userId: user.userId,
                                })
                              }
                              fw={500}
                              fz={16}
                              key={item}>
                              {mapStatusUser[item].textRu}
                            </Menu.Item>
                          ),
                      )}
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              </Paper>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
